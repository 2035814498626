/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.SignIn {
  height: 100vh;
  display: flex; }
  .SignIn .hero-body {
    padding: 2rem 1.5rem;
    flex-direction: column;
    justify-content: center; }
  .SignIn .panel {
    max-width: 370px;
    height: auto;
    margin: auto;
    background-color: white;
    border-top: 4px solid #9BD4F5;
    border-radius: 3px;
    animation-delay: 0.5s; }
    .SignIn .panel figure {
      width: 60%;
      margin-left: 20%; }
    .SignIn .panel .tag {
      color: #9BD4F5;
      font-size: 14px;
      margin-bottom: 10px; }
    .SignIn .panel .select {
      width: 100%; }
  .SignIn .link-standard a {
    color: #27AEE3 !important; }
  .SignIn .link-chile a {
    color: #35C4B5 !important; }
    .SignIn .link-chile a:hover {
      color: #006FCF !important; }
  .SignIn .button-asia {
    background-color: #0063ca !important;
    border-radius: 10px; }
    .SignIn .button-asia:hover {
      background-color: #002eb8 !important; }
  .SignIn .recaptcha-box {
    display: flex;
    justify-content: center; }

.sign-in-hero {
  background: url("../../../resources/images/assets/desktop.jpg") no-repeat center center fixed;
  background-size: cover;
  width: 55%;
  padding: 5em;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative; }
  .sign-in-hero .hero-filter {
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(2, 120, 243, 0.75); }
  .sign-in-hero figure {
    display: flex;
    align-items: center; }
  .sign-in-hero h2 {
    color: #f8fefc;
    font-size: 40px;
    font-weight: 500;
    margin-left: .75em;
    position: relative;
    line-height: 40px; }

.sign-in-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 45%; }
  .sign-in-form .sign-in-language {
    position: absolute;
    top: 10px;
    right: 30px; }
    .sign-in-form .sign-in-language select {
      color: #646464; }
