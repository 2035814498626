
.instructions {
  width: 100%;
  height: 100%;
  p {
    margin: 10%;
    text-align: center;
    font-size: 3.2vw;
    color: #247691;
    font-weight: 700;
  }
}

