.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word;
}

.return-box {
  //padding: 1em;
  margin: .5em;
  border-radius: .5em;

  p {
    color: #404040;
    font-weight: 400;
  }
}