.box-data {
	position: relative;
	background-color: white;
	box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.3);
	border-radius: 10px;
	.box-content {
		padding-top: 2rem;
		padding-bottom: 1.25rem;
		text-align: center;
		.box-icon {
			box-shadow: 0 9px 7px -6px rgba(0, 0, 0, 0.3);
			font-size: 2rem;
			width: 60px;
			height: 60px;
			position: absolute;
			top: -30px;
			left: calc(50% - 30px);
			border-radius: 50%;
			background-color: transparent;
			padding: 5px;

			&.red {
				background-color: #de1865;
			}

			&.yellow {
				background-color: #efb70a;
			}

			&.green {
				background-color: #5bd0b3;
			}

			.fas,
			.far {
				color: white;
			}
		}

		.box-text {
			padding-top: 3px;
			font-size: 0.75rem;
			font-weight: 600;
		}
		.box-value {
			font-size: 2rem;
			font-weight: 600;
		}
	}
}
