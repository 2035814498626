/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
footer {
  position: absolute;
  bottom: 0;
  min-height: 40px;
  background: #e3e3e3;
  width: 100%;
  text-align: right;
  padding: 10px 25px 5px; }
  footer div {
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
    align-items: center; }
    footer div p span {
      margin: 0 5px; }
  footer figure {
    height: 35px;
    width: 35px;
    max-height: 35px; }
