/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
input[type=file]::-webkit-file-upload-button {
  background-color: #a6bcd6;
  border: none;
  border-radius: 7.5px;
  color: #1b85f3;
  padding: 3px 15px;
  cursor: pointer; }

.file-name {
  min-width: 16em !important; }

#asia-input {
  color: #a2a6ad;
  border-radius: 10px;
  box-shadow: none; }
  #asia-input::placeholder {
    color: #ccc; }

.border-success {
  border-color: green !important; }
  .border-success:focus {
    border-color: green !important; }

.border-error {
  border-color: red !important; }
  .border-error:focus {
    border-color: red !important; }
