/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.Panel__heading-text {
  background-color: #0063ca;
  padding: 10px 20px;
  margin: 10px 0;
  border-radius: 10px;
  color: white;
  font-size: 1.5em; }

.Panel {
  /* div {
     display: block;
   }*/ }
  .Panel * {
    box-sizing: border-box; }
  .Panel .panel {
    background-color: #ffffff;
    margin-bottom: 50px;
    border-width: 0;
    border-radius: 10px; }
  .Panel .panel-default {
    border-color: #f5f5f5; }
  .Panel .panel-default > .panel-header {
    border-color: #f5f5f5; }
  .Panel .panel-header {
    padding: 20px 20px 10px 50px;
    border-bottom: 1px solid transparent;
    border-bottom-width: 0;
    border-radius: 10px 10px 0 0;
    font-weight: 400;
    margin: 0; }
  .Panel .panel-body {
    border-radius: 0 0 10px 10px;
    padding: 2%; }
    .Panel .panel-body .panel-top-content {
      flex-direction: initial; }
  .Panel .shadas {
    box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.3); }

.Panel__extra-title, .Panel__extra-title-main {
  display: flex;
  justify-content: space-between;
  padding: 10px;
  border-radius: 10px;
  color: white;
  margin: 5px 0; }

.Panel__extra-title-main {
  background-color: #0063ca;
  font-size: 1.35em;
  letter-spacing: 1px; }

.Panel__extra-title {
  background-color: #4F74A6;
  font-size: 1.25em; }
