/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
div.panel-top-content {
  display: flex;
  flex-direction: row-reverse;
  padding-bottom: 1rem; }

.activate-2fa-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 90%; }
  .activate-2fa-form p {
    margin: 15px 0;
    text-align: center;
    color: #00575B;
    font-size: 1.25em; }
  .activate-2fa-form input {
    width: 200px !important; }
  .activate-2fa-form button {
    width: 200px;
    margin-bottom: 30px !important; }

.account-profile-form {
  background-color: white;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 0 8px #EAEAEA; }
