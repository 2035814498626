/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.Modal .modal {
  z-index: 1000; }

.Modal .modal-content {
  background-color: white;
  border-radius: 15px;
  position: relative;
  overflow: initial;
  min-width: 50%;
  max-width: 80vw;
  max-height: 80vh;
  padding: 25px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center; }

.Modal .modal-alert {
  background-color: #0278f3;
  border: 4px solid white;
  width: 65px;
  height: 65px;
  border-radius: 50%;
  position: absolute;
  top: -40px;
  left: -40px;
  z-index: 1;
  display: flex;
  justify-content: center;
  align-items: center; }
  .Modal .modal-alert i {
    font-size: 2.5em;
    color: white; }

.Modal .modal-title {
  font-size: 1.35em;
  text-align: center;
  font-weight: 600; }

.Modal .modal-action-btns {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  justify-content: space-around;
  margin-top: 20px; }
  .Modal .modal-action-btns .custom-btn, .Modal .modal-action-btns a > .custom-btn {
    margin: 15px;
    min-width: 43%; }

.Modal .wider-modal {
  min-width: 1200px; }
