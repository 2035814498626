.FormLogin {
  font-family: "Open Sans";
  .field {
    margin-right: 0 !important;
    // .control.login-submit {
    //   text-align: center;
    // }
    .button.is-info.is-rounded {
      width: 150px;
      border-radius: 10px;
    }
    .control {
      &.login-submit {
        text-align: center;
      }
      &.has-icons-right {
        .icon {
          &.is-right {
            cursor: pointer;
            pointer-events: auto;
            // top: 5px;
          }
        }
      }
    }
  }
  .button-submit {
    margin-top: 10px;
  }
}

.center{
  text-align: center;
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag).blue-link {
  color: #3273dc;
}