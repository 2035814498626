.not-credit-box {
    width: 75%;
    margin: 20px auto;
    display: flex;
    flex-direction: column;
    align-items: center;

    h2 {
        text-align: center;
        font-size: 1.5em;
    }

    a {
        width: 230px;
        margin: 20px;
    }
}