/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.SignIn {
  height: 100vh; }
  .SignIn .hero-body {
    padding: 2rem 1.5rem;
    flex-direction: column;
    justify-content: center; }
  .SignIn .hero.is-info {
    background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, 0)), url("../../resources/images/bg-general.png") no-repeat center center fixed;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover; }
  .SignIn .panel {
    max-width: 370px;
    height: auto;
    margin: auto;
    background-color: white;
    border-radius: 13px;
    animation-delay: 0.5s; }
    .SignIn .panel figure {
      width: 60%;
      margin-left: 20%; }
    .SignIn .panel .tag {
      color: #9BD4F5;
      font-size: 14px;
      margin-bottom: 10px; }
    .SignIn .panel .select {
      width: 100%; }
