/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/

$first_color: #E4002B; //Dropdown Nombre
$second_color: #A6192E; //Headders tablas
$third_color: #C8102E; //Headers paneles
$fourth_color: #9BD4F5;
$fifth_color: #6CC4EE;
$sixth_color: #fb3425;
$seventh_color: #EEEEEE;
$eighth_color: #e3e3e3;
$nineth_color: #f5f5f5;
$tenth_color: #ffffff;
$eleventh_color: #c5c5c5;
$twelfth_color: #646464;
$thirteenth_color: rgb(248, 254, 252);   
$input_color: rgb(239, 246, 255);
$input_text:  rgb(162, 166, 173);
/*Azules para demo-asia*/
$blue: #0278f3;
$dark-blue: #0063ca;
$light-blue: #1b85f3;
$grey-blue: #4F74A6;
$deep-blue: #0D2568;
/*variables de bulma*/
$primary: $dark-blue;
$link: $second_color;
$info: $third_color;
$danger: $grey-blue;
/*Variables en arrays*/
$colorPanel: (1: #de1865, 2: #efb70a, 3: #5bd0b3, 4: #3088ac, 5: #b937b5, 6:#EF6F6C);
$colorPanelReversed: (1:#EF6F6C, 2: #b937b5, 3: #3088ac, 4: #5bd0b3, 5: #efb70a, 6: #de1865);
/*Sizes*/
$sizes: 0, 8px, 16px, 24px, 32px, 40px, 48px, 56px, 64px, 72px, 80px;
