/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.Filters__main-container {
  display: flex;
  justify-content: center; }

.filters-options {
  color: #505050 !important;
  font-size: 16px !important;
  margin: 0 12px;
  width: 190px !important;
  height: 45px !important; }
  .filters-options::placeholder {
    color: #969696 !important; }

.filter-inputs {
  font-size: .8em;
  min-height: 45px; }
