/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.menuContent {
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 100%;
  color: #f8fefc;
  font-weight: lighter; }
  @media (max-width: 1023px) {
    .menuContent {
      justify-content: flex-start; } }
  .menuContent .notification-request {
    display: flex;
    align-items: center;
    padding: 0 5px;
    position: relative; }
    .menuContent .notification-request .number-request {
      border-radius: 0.8em;
      -moz-border-radius: 0.8em;
      -webkit-border-radius: 0.8em;
      background: red;
      position: absolute;
      font-size: 0.6rem;
      color: white;
      display: inline-block;
      font-weight: bold;
      line-height: 1.6em;
      width: 15px;
      height: 15px;
      text-align: center;
      top: 12px;
      right: 8px; }
