/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.hidden {
  display: none; }

.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word; }

.terms-box {
  background: #EEE;
  padding: 1em;
  margin: .5em;
  border-radius: .5em; }
  .terms-box p {
    color: #404040;
    font-weight: 400; }
