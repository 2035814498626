/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.basic-single .select__dropdown-indicator {
  color: #0063ca !important; }

.is-danger2 .select__control {
  border-color: #fb3425 !important; }

/*&:focus {
    //box-shadow: 0 0 0 0.125em rgba(251, 52, 37, 0.25) !important;
    border-color: black;
  }*/
.is-success2 {
  /*&:focus {
    //box-shadow: 0 0 0 0.125em rgba(251, 52, 37, 0.25) !important;
    border-color: black;
  }*/ }
  .is-success2 .select__control {
    border-color: #23D15B !important; }

.menu-list {
  z-index: 1000 !important; }

/*.is_danger {
    &:focus, :active {
      box-shadow: 0 0 0 0.125em rgba(251, 52, 37, 0.25) !important;
    }
  }
}*/
