/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.Layout {
  padding: 0;
  background-color: #f9fcff;
  min-height: 100vh; }
  .Layout .be-wrapper {
    min-height: 100vh;
    display: flex; }
  .Layout .be-content {
    position: relative;
    left: 0;
    min-height: 100%;
    width: 80%;
    margin-top: 87px;
    display: flex;
    justify-content: center; }
  .Layout .main-content {
    padding: 60px 25px 25px;
    margin: 0; }
  .Layout .container-fluid {
    margin-right: auto;
    margin-left: auto;
    padding-left: 15px;
    padding-right: 15px; }
  .Layout .santander-bar {
    height: auto;
    position: absolute;
    width: 10rem;
    z-index: 999;
    left: 63vw;
    top: .75rem;
    object-fit: scale-down; }
  .Layout .photo {
    flex: 1;
    height: 55px;
    width: 400px; }
