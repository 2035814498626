/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.ConfirmCredit__main .ConfirmCredit__content {
  width: 75%;
  margin: 20px auto; }
  .ConfirmCredit__main .ConfirmCredit__content .confirm-field {
    display: flex;
    justify-content: space-between;
    margin: 12px 0;
    border-bottom: 1px solid #d1d1d1; }
    .ConfirmCredit__main .ConfirmCredit__content .confirm-field p:nth-child(1) {
      color: #0D2568; }
    .ConfirmCredit__main .ConfirmCredit__content .confirm-field p:nth-child(2) {
      color: #1B1B1B; }
  .ConfirmCredit__main .ConfirmCredit__content .accept-terms {
    display: flex;
    align-items: center;
    margin: 20px 0; }
    .ConfirmCredit__main .ConfirmCredit__content .accept-terms p {
      font-size: .9em;
      font-weight: 600;
      color: #676767;
      margin-left: 20px; }
  .ConfirmCredit__main .ConfirmCredit__content .confirm-BTNS {
    display: flex; }
    .ConfirmCredit__main .ConfirmCredit__content .confirm-BTNS button {
      flex: 1;
      margin: 20px; }
