.pagination.is-centered {
  justify-content: center;
}

ul.pagination-list {
  flex-grow: unset;
}

.pagination {
  margin: 0rem;
}
