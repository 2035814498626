.bar-chart-box {
	box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.3);
	width: 100%;
	max-width: 1000px;
	height: 400px;
	padding-bottom: 35px;
	background-color: #fff;
	border-radius: 0.25rem;
	.title {
		font-size: 1.125rem;
		text-align: center;
		padding-top: 15px;
		margin-bottom: 0px !important;
	}
}
