.send-information {
	padding: 15px;
	width: 100%;
	position: relative;
	@media only screen and (min-width: 768px) {
		padding: 15px 20% 15px;
	}

	.header {
		background-color: #0063ca;
		color: white;
		font-size: 16px;
	}

	.title {
		font-size: 14px;
		font-weight: 600;
		min-height: 28px;
		margin-bottom: 0 !important;
		padding: 5px;
	}

	.shadow {
		box-shadow: 2px 0 15px rgba(100, 100, 100, 0.25);
	}

	.counter-container {
		position: relative;
    cursor: default;
    width: 200px;
    height: 200px;
		left: 50%;
		transform: translateX(-50%);

		.counter-value {
			position: absolute;
			display: inline-flex;
			margin: 0 auto;
			top: 35%;
			font-size: 40px;
			font-weight: 600;
			color: #676767;
			left: 35%;
			width: 65px;
			height: 50px;
			small {
				font-size: 16px;
				font-weight: 400;
				padding-top: 15px;
			}
		}
	}
}
