/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.informative-boxes .column:nth-child(6n+1) .information-box .icon {
  background: #de1865; }

.informative-boxes .column:nth-child(6n+2) .information-box .icon {
  background: #efb70a; }

.informative-boxes .column:nth-child(6n+3) .information-box .icon {
  background: #5bd0b3; }

.informative-boxes .column:nth-child(6n+4) .information-box .icon {
  background: #3088ac; }

.informative-boxes .column:nth-child(6n+5) .information-box .icon {
  background: #b937b5; }

.informative-boxes .column:nth-child(6n+6) .information-box .icon {
  background: #EF6F6C; }

.informative-boxes .column:nth-child(6n+1) .information-box .icon .icon {
  background: #de1865; }

.informative-boxes .column:nth-child(6n+2) .information-box .icon .icon {
  background: #efb70a; }

.informative-boxes .column:nth-child(6n+3) .information-box .icon .icon {
  background: #5bd0b3; }

.informative-boxes .column:nth-child(6n+4) .information-box .icon .icon {
  background: #3088ac; }

.informative-boxes .column:nth-child(6n+5) .information-box .icon .icon {
  background: #b937b5; }

.informative-boxes .column:nth-child(6n+6) .information-box .icon .icon {
  background: #EF6F6C; }

.informative-boxes.inverted .column:nth-child(6n+1) .icon {
  background: #EF6F6C; }

.informative-boxes.inverted .column:nth-child(6n+2) .icon {
  background: #b937b5; }

.informative-boxes.inverted .column:nth-child(6n+3) .icon {
  background: #3088ac; }

.informative-boxes.inverted .column:nth-child(6n+4) .icon {
  background: #5bd0b3; }

.informative-boxes.inverted .column:nth-child(6n+5) .icon {
  background: #efb70a; }

.informative-boxes.inverted .column:nth-child(6n+6) .icon {
  background: #de1865; }

.informative-boxes.inverted .column:nth-child(6n+1) .icon .icon {
  background: #EF6F6C; }

.informative-boxes.inverted .column:nth-child(6n+2) .icon .icon {
  background: #b937b5; }

.informative-boxes.inverted .column:nth-child(6n+3) .icon .icon {
  background: #3088ac; }

.informative-boxes.inverted .column:nth-child(6n+4) .icon .icon {
  background: #5bd0b3; }

.informative-boxes.inverted .column:nth-child(6n+5) .icon .icon {
  background: #efb70a; }

.informative-boxes.inverted .column:nth-child(6n+6) .icon .icon {
  background: #de1865; }

.informative-boxes {
  /* Estilos para IE11 resuelve sin flexbox el posicionamiento dentro de las cajas informativas del dashboard */ }
  .informative-boxes .information-box {
    color: #ffffff;
    border-radius: 10px;
    position: relative;
    margin-bottom: 20px;
    margin-top: 20px;
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    background: linear-gradient(#7fbdfc, #0278f3); }
    .informative-boxes .information-box .icon {
      position: absolute;
      left: calc(50% - 30px);
      top: -30px;
      border-radius: 50%;
      width: 60px;
      height: 60px;
      box-shadow: 0 9px 7px -6px rgba(0, 0, 0, 0.3);
      flex: 1; }
      .informative-boxes .information-box .icon i {
        color: #ffffff;
        font-size: 2rem; }
    .informative-boxes .information-box .content-title {
      background-color: #0063ca;
      display: flex;
      align-items: center;
      justify-content: center;
      flex: 2;
      border-top-left-radius: 10px;
      border-top-right-radius: 10px; }
      .informative-boxes .information-box .content-title .title {
        font-size: .85rem;
        margin: 5px;
        color: white;
        text-align: center;
        font-weight: normal; }
    .informative-boxes .information-box .big-value {
      display: flex;
      font-size: 1.25rem;
      text-align: center;
      color: #f8fefc;
      padding: 5px 0;
      flex-grow: 3; }
      .informative-boxes .information-box .big-value .content {
        margin: auto;
        width: 100%; }
        .informative-boxes .information-box .big-value .content .columns:not(:last-child) {
          border-bottom: 1px solid; }
        .informative-boxes .information-box .big-value .content .columns {
          margin: 0; }
          .informative-boxes .information-box .big-value .content .columns .column {
            padding: 5px 0;
            display: flex;
            justify-content: flex-end;
            padding-right: 2px; }
            .informative-boxes .information-box .big-value .content .columns .column:not(:first-child) {
              justify-content: flex-start;
              padding-left: 2px; }
  .informative-boxes *::-ms-backdrop, .informative-boxes .information-box {
    display: block; }
  .informative-boxes *::-ms-backdrop, .informative-boxes .information-box .content-title {
    margin-top: 0px;
    height: auto; }
  .informative-boxes *::-ms-backdrop, .informative-boxes .information-box .big-value {
    padding-bottom: 0px; }
