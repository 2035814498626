.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word;
}

.butt {
  font-size: 5px;
  color: #000000;
  text-align: center;
  font-weight: normal;
  padding: 10px;
}