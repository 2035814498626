.product-holder{    
    border: 1px solid #b9b9b9;
    margin: 1rem;
    padding: 1rem 1rem 1rem 2.5rem;
    border-radius: 3px;
    box-shadow: 0 9px 7px -6px rgba(0, 0, 0, 0.3);
    .product-title{
        font-weight: bold;
        font-size: 1.5rem;
        margin-bottom: 1rem;
    }
    .product-subtitle{
        font-size: 1.5rem;
        margin-left: .75rem;
        margin-bottom: 1rem;
    }
    .product-text{
        margin-left: .75rem;
        margin-bottom: 1rem;
    }
}