/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.Dropdown {
  position: relative;
  z-index: 200; }
  .Dropdown a {
    outline: none; }
  .Dropdown .be-connections {
    min-width: 304px; }
  .Dropdown .navbar {
    position: relative;
    min-height: 65px; }
  .Dropdown .notification-invoice {
    display: flex;
    align-items: center;
    padding: 0 10px;
    margin: 0 20px 0 5px;
    position: relative; }
    .Dropdown .notification-invoice i {
      font-size: 2.3rem;
      color: #646464; }
    .Dropdown .notification-invoice .number-invoice {
      background-color: #fff;
      border: 2px solid #646464;
      border-radius: 50%;
      box-shadow: -4px 2px white;
      position: absolute;
      font-size: 0.6rem;
      color: #646464;
      font-weight: bold;
      width: 18px;
      height: 18px;
      text-align: center;
      top: 10px;
      right: 2px;
      padding-top: 1px; }
  .Dropdown .be-user-nav {
    width: 100%; }
    .Dropdown .be-user-nav > li.dropdown > a img {
      border-radius: 50%;
      max-height: 45px;
      max-width: 45px;
      margin-top: 8px; }
    .Dropdown .be-user-nav .dropdown {
      width: 100%;
      height: 53px; }
      .Dropdown .be-user-nav .dropdown .content-information {
        width: 100%; }
        .Dropdown .be-user-nav .dropdown .content-information .content-information-user {
          display: flex;
          width: calc(100% - 70px); }
          .Dropdown .be-user-nav .dropdown .content-information .content-information-user .information-user {
            display: flex;
            justify-content: center;
            padding: 3px;
            flex-direction: column;
            width: 100%;
            margin-right: 5px; }
            .Dropdown .be-user-nav .dropdown .content-information .content-information-user .information-user .full-name {
              /*text-align: right;
              font-size: 1.07rem;
              color: $first_color;
              font-weight: bold;
              height: 24px;
              width: 100%;
              //  max-width: 500px;
              text-overflow: ellipsis;
              white-space: nowrap;
              overflow: hidden;
              text-decoration: dashed;*/
              text-align: right;
              font-size: .8rem;
              color: #646464;
              font-weight: bold;
              height: 24px;
              width: 100%;
              /* text-overflow: ellipsis; */
              white-space: nowrap;
              overflow: none;
              -webkit-text-decoration: dashed;
              text-decoration: dashed; }
            .Dropdown .be-user-nav .dropdown .content-information .content-information-user .information-user .name-user {
              text-align: right;
              font-size: 0.61rem;
              color: #646464; }
            .Dropdown .be-user-nav .dropdown .content-information .content-information-user .information-user .type-user {
              text-align: right;
              font-size: 0.58rem;
              color: #646464; }
  .Dropdown .navbar-item img {
    max-height: none; }
  .Dropdown .shadas {
    box-shadow: 0 9px 7px -6px rgba(0, 0, 0, 0.3); }
  .Dropdown .navbar-right .be-connections {
    left: auto;
    right: 25px; }
  .Dropdown .user-info {
    background-color: #9BD4F5;
    color: #ffffff;
    overflow: hidden;
    padding: 20px 15px 14px;
    border-radius: 2px 2px 0 0;
    margin-bottom: 7px; }
  .Dropdown .user-name {
    display: block;
    font-size: 19px;
    line-height: 22px;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden; }
  .Dropdown .user-position {
    display: block;
    text-overflow: ellipsis;
    white-space: nowrap;
    overflow: hidden;
    padding-left: 13px;
    position: relative; }
  .Dropdown .be-top-header .page-title {
    font-size: 22px;
    float: left;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 15px;
    line-height: 50px; }
  .Dropdown .be-top-header .navbar-nav > li > a {
    line-height: 50px; }
  .Dropdown .be-top-header .be-right-navbar {
    background-image: url(../../../../resources/images/assets/bg-nav-responsive-azul.png);
    background-color: #046fd1;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: contain;
    z-index: 1 d; }
  .Dropdown .be-top-header .navbar-header .navbar-brand {
    height: 65px;
    margin: 0;
    padding: 10px;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center; }
  .Dropdown .be-top-header .navbar-header {
    width: inherit;
    height: 84px;
    display: flex;
    align-items: center; }
  .Dropdown .separador {
    margin-left: 12px; }
  .Dropdown .separador-right {
    font-size: 23px;
    line-height: 35px;
    float: left;
    -webkit-box-flex: 1;
    -webkit-flex: 1 1 auto;
    -ms-flex: 1 1 auto;
    flex: 1 1 auto;
    padding: 0 20px;
    padding-right: 40px; }
  .Dropdown li.box-icon > a {
    color: #504e4e;
    padding: 10px 20px;
    min-width: 175px;
    font-weight: 400;
    display: flex;
    clear: both;
    line-height: 1.42857143; }
  .Dropdown li.box-icon > a > i {
    display: block;
    padding: 3px 6px; }
  .Dropdown .avatar {
    width: 45px;
    height: 45px;
    border: 2.5 solid #ffffff;
    border-radius: 50%;
    margin-left: 20px;
    margin-top: 7px; }
  .Dropdown .avatarMin {
    border-radius: 50%;
    max-height: 55px;
    max-width: 45px;
    border: 2.5 solid #ffffff; }
    @media (max-width: 1023px) {
      .Dropdown .avatarMin {
        margin-top: 2px;
        margin-left: 0; } }
  .Dropdown .triangulo {
    margin-left: 15px; }
  .Dropdown .ocult-big {
    display: none; }
  .Dropdown .ttt {
    width: 100%; }
  .Dropdown .navbar-brand-extend {
    position: relative;
    float: left;
    overflow: hidden;
    min-height: 40px;
    height: 84px;
    width: 20%; }
  .Dropdown .navbar-extend {
    width: 100%;
    background-color: #ffffff;
    display: flex;
    align-items: center;
    position: fixed;
    box-shadow: 2px 0 15px rgba(100, 100, 100, 0.25); }
    .Dropdown .navbar-extend .section-title {
      color: #0278f3;
      font-size: 2em;
      margin-left: 40px; }
  .Dropdown .navbar-menu-extend {
    background-color: white;
    overflow: hidden;
    width: 80%;
    height: 84px;
    padding: 5px 15px;
    display: flex;
    align-items: center;
    /* @media (min-width: 960px) {
      width: 10%;
    }
    @media (min-width: 1200px) {
      width: 20%;
    } */ }
    .Dropdown .navbar-menu-extend h2 {
      font-size: 2em;
      color: #0063CA;
      font-weight: 300;
      white-space: nowrap;
      padding-left: 10px;
      letter-spacing: 1px; }
  .Dropdown .navbar-end-extend {
    position: relative;
    float: right;
    z-index: 2;
    width: 50px;
    margin-top: -50px;
    margin-right: 20px; }
  .Dropdown .modal-pop {
    position: relative;
    float: left;
    width: 100%; }
  .Dropdown .icon-medium {
    line-height: 50px;
    font-size: 28px; }
  .Dropdown .boxSettings {
    position: fixed;
    z-index: 1000;
    padding: 15px;
    right: 0;
    top: 70px;
    display: none; }
    .Dropdown .boxSettings.open {
      display: block; }
  .Dropdown .boxSettingsOut {
    position: fixed;
    z-index: 31;
    width: 200px;
    padding-bottom: 10px;
    padding-top: 10px;
    text-align: center;
    color: #ffffff;
    display: none;
    background-color: #323232; }
  .Dropdown .box {
    color: #000000;
    border-radius: 0;
    padding: 0rem; }
  .Dropdown .box-icon:hover {
    background-color: #e3e3e3; }
  .Dropdown button.dropdown-toggle {
    background: none;
    border: 0;
    color: inherit;
    outline: none;
    cursor: pointer;
    font: inherit;
    line-height: normal;
    overflow: visible;
    padding: 0;
    -webkit-user-select: none;
    /* for button */
    -webkit-appearance: button;
    /* for input */
    -moz-user-select: none;
    -ms-user-select: none;
    height: 50px; }
    .Dropdown button.dropdown-toggle:focus:not(:active), .Dropdown button.dropdown-toggle.is-focused:not(:active) {
      box-shadow: none; }
    .Dropdown button.dropdown-toggle .avatar-short-name {
      border-radius: 10px;
      background-color: #0063ca;
      height: 45px;
      width: 45px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #fff;
      font-size: 2rem; }
  .Dropdown .santander {
    display: flex;
    align-items: center; }
    .Dropdown .santander img {
      width: 10em;
      margin-left: 11vw;
      margin-bottom: 1vh; }
  @media screen and (min-width: 1024px) {
    .Dropdown .ocult-small {
      display: none; }
    .Dropdown .ocult-big {
      display: block;
      position: relative; }
    .Dropdown .boxSettings {
      right: 10px;
      left: auto; } }
  @media screen and (max-width: 1023px) {
    .Dropdown .content-information-user {
      display: none; } }
  @media screen and (max-width: 1439px) {
    .Dropdown .hide-logo {
      display: none; } }

@media screen and (min-width: 1400px) {
  .navbar-menu-extend h2 {
    font-size: 2.25em; } }
