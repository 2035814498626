/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.MixedPayments__main h2 {
  text-align: end;
  padding: 10px;
  font-size: 1.25em;
  font-weight: 600; }

.MixedPayments__main form {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px 0; }
  .MixedPayments__main form .form-BTNS {
    display: flex;
    width: 60%;
    margin: 30px 50px; }
    .MixedPayments__main form .form-BTNS button, .MixedPayments__main form .form-BTNS a {
      flex: 1;
      margin: 0 10px; }

.MixedPayments__main .form-field {
  display: flex;
  align-items: center;
  margin-top: 10px; }
  .MixedPayments__main .form-field .special-field {
    position: relative; }
    .MixedPayments__main .form-field .special-field label {
      position: absolute;
      top: -25px;
      left: 10px;
      font-weight: 600; }
  .MixedPayments__main .form-field i {
    color: #0063ca;
    font-size: 1.75em; }
  .MixedPayments__main .form-field input, .MixedPayments__main .form-field i {
    margin-right: 10px; }
