.card-holder{
    display: flex;
    align-items: center;
        .trash-icon{
            color: red;
            cursor: pointer;
        }
        .card-icon{
            color: #000;
        }
        i{ 
            margin-right: 1rem;
            font-size: 2rem;    
        }
        p{
            margin-right: 1rem;
            font-size: 1.5rem;
            color: #000;
        }
}