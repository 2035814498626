@import "src/styles/_constants.scss";

* {
  font-family: 'Encode Sans Medium', Arial, sans-serif;
}

html {
  min-height: 100%;
  position: relative;
}

body {
  font-family: 'Encode Sans Medium', Arial, sans-serif;
  font-size: 13px;
  line-height: 1.42857143;
  color: #404040;
  margin: 0;
  padding: 0;
  height: 100%;
}

code {
  // font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
  // monospace;
  font-family: 'Encode Sans Medium', Arial, sans-serif;
}

@font-face {
  font-family: 'Open Sans Cond Light';
  src: url('fonts/OpenSans-CondLight.ttf');
}

@font-face {
  font-family: 'Open Sans Cond Bold';
  src: url('fonts/OpenSans-CondBold.ttf');
}

@font-face {
  font-family: 'Open Sans Regular';
  src: url('fonts/OpenSans-Regular.ttf');
}

@font-face {
  font-family: 'Encode Sans Medium';
  src: url('fonts/EncodeSans/EncodeSans-Medium.ttf');
}

@font-face {
  font-family: 'Encode Sans Medium';
  src: url('fonts/EncodeSans/EncodeSans-Regular.ttf');
}

h1 {
  font-family: "Open Sans Cond Bold";
}

h3 {
  font-family: "Open Sans Cond Light";
}

button, input, select, textarea {
  // font-family: 'Poppins', Arial, sans-serif;
  border-radius: 10px !important;
}

input {
  &:focus {
    border-color: $grey-blue !important;
  }
}

table {
  border-collapse: collapse;
  border-spacing: 0;
  background-color: transparent;
  width: 100%;
  max-width: 100%;
  margin-bottom: 0;
}

.table > caption + thead > tr:first-child > th, .table > colgroup + thead > tr:first-child > th, .table > thead:first-child > tr:first-child > th, .table > caption + thead > tr:first-child > td, .table > colgroup + thead > tr:first-child > td, .table > thead:first-child > tr:first-child > td {
  border-top: 0;
}

.panel-table tr th:first-child, .panel-table tr td:first-child {
  padding-left: 10px;
}

.panel-table tr th:last-child, .panel-table tr td:last-child {
  padding-right: 20px;
}

th {
  text-align: left;
}

.table > thead > tr > th, .table > tbody > tr > th, .table > tfoot > tr > th, .table > thead > tr > td, .table > tbody > tr > td, .table > tfoot > tr > td {
  border-bottom: 2px solid #ddd;
  font-size: 13px;
  font-weight: 700;
  line-height: 1.42857;
  padding: 7px 10px;
  vertical-align: middle;
  height: 55px;
  @media only screen and (max-width: 600px) {
    font-size: 11px;
  }
}

.table > tbody > tr > td {
  font-weight: 400;
}

/**
 Text
 */
.text-verde {
  color: $fourth_color !important;
}

.text-white {
  color: $seventh_color !important;
}

.checkbox {
  width: 20px;
  height: 20px;
  border-radius: 5px;
  display: block;
  float: left;
  margin-right: 10px;
}

.label-checkbox {
  color: $twelfth_color;
  font-size: 13px;
  display: block;
}

.label:not(:last-child) {
  margin-bottom: 0; // Alinea los select y los custom-input para que tengan el mismo margin
}

div.field.is-horizontal {
  padding-left: 5%;
  padding-right: 5%;
}

.button {
  font-size: 14px;
  font-weight: bolder;
  font-family: 'Encode Sans Medium', Arial, sans-serif;
}

.custom-btn {
  height: 40px;
  border-radius: 10px;
}

.custom-input {
  background-color: #F0F6FF;
  border: none;
  outline: none;
  color: #0D2568;
  font-size: 1.25em;
  border-radius: 10px;
  padding: 5px 10px;

  &::placeholder {
    color: 80848A;
  }
}

.react-datepicker-wrapper, .react-datepicker__input-container {
  width: 100%;
}

/*Select Single*/

.basic-single{
  .select__control {
  border-color: hsl(0, 0%, 85.9%);
  height: 36px;
  min-height: 36px;
  box-shadow: inset 0 1px 2px rgba(10, 10, 10, 0.1);
  border-radius: 10px;
    &.select__control--is-focused, &.select__control--menu-is-open {
      min-height: 36px;
      border-color: #27AEE3;
      box-shadow: 0 0 0 0.125em rgba(39, 174, 227, 0.25);
      height: 36px;
    }
    .select__placeholder {
      color: hsl(0, 0%, 87.5%);
    }
    .select__indicator.select__dropdown-indicator {
      color: $first_color;
    }
  }
}

/*Margin*/
@each $size in $sizes {
  .mt-#{$size} {
    margin-top: $size;
  }
  .ml-#{$size} {
    margin-left: $size;
  }
  .mr-#{$size} {
    margin-right: $size;
  }
  .mb-#{$size} {
    margin-bottom: $size;
  }
  .m-#{$size} {
    margin: $size;
  }
  .pt-#{$size} {
    padding-top: $size;
  }
  .pl-#{$size} {
    padding-left: $size;
  }
  .pr-#{$size} {
    padding-right: $size;
  }
  .pb-#{$size} {
    padding-bottom: $size;
  }
  .p-#{$size} {
    padding: $size;
  }
}


.ReactTable {
  .-sort-desc {
    box-shadow: none !important;
    &:before {
      content: "▼";
      float: right;
      margin-right: 16px;
    }
  }
  .-sort-asc {
  box-shadow: none !important;
    &:before {
      content: "▲";
      float: right;
      margin-right: 16px;
    }
  }
} 
