/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.dashboard h4 {
  font-weight: bold; }

.dashboard .date-range-dashboard {
  box-shadow: 0 9px 20px -5px rgba(0, 0, 0, 0.3);
  background-color: #fff;
  border-radius: 10px; }
  .dashboard .date-range-dashboard .column {
    padding-top: 0; }
  .dashboard .date-range-dashboard .date-range-title {
    text-align: center;
    font-size: 1.25rem;
    margin-bottom: 25px;
    font-weight: 700;
    color: #363636; }
  .dashboard .date-range-dashboard .btn-send {
    margin-top: 24px;
    padding-left: 20px;
    padding-right: 20px; }

.block {
  width: 100%;
  height: 100px;
  background-color: white;
  border-radius: 0 0 3px 3px;
  padding: 0.5rem;
  box-shadow: 0 9px 7px -6px rgba(0, 0, 0, 0.3);
  border-bottom: 5px solid rgba(0, 0, 0, 0.2); }

.wordwrap {
  white-space: pre-line !important;
  word-wrap: break-word; }

.charts-inputs-main {
  width: 100%;
  margin-bottom: 15px; }
  .charts-inputs-main div {
    margin: 5px 0;
    justify-content: center; }
    .charts-inputs-main div label {
      margin: 0 10px; }
      .charts-inputs-main div label input {
        margin-right: 5px; }
