/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
div.select-lead-require {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center; }
  div.select-lead-require .width-select-lead-require {
    width: 100%; }

span.texto-file {
  font-size: 13px;
  color: #1D8ECE; }

.Panel1 {
  /* div {
     display: block;
   }*/ }
  .Panel1 * {
    box-sizing: border-box; }
  .Panel1 .panel {
    background-color: #ffffff;
    margin-bottom: 25px;
    border-width: 0;
    border-radius: 3px; }
  .Panel1 .panel-default {
    border-color: #f5f5f5; }
  .Panel1 .panel-default > .panel-header {
    border-color: #f5f5f5; }
  .Panel1 .panel-header {
    padding: 20px 20px 10px;
    border-bottom: 1px solid transparent;
    border-bottom-width: 0;
    border-radius: 3px 3px 0 0;
    font-size: 18px;
    font-weight: 200;
    margin: 0; }
  .Panel1 .panel-body {
    border-radius: 0 0 3px 3px;
    padding: 0.5rem; }
    .Panel1 .panel-body .panel-top-content {
      flex-direction: initial; }
  .Panel1 .bg-panel {
    background-color: #C8102E !important; }
