.loader-counter {
	svg {
		&:first-of-type {
			position: absolute;
			z-index: -1;
		}
	}

	circle {
		transition: stroke-dashoffset 0.35s;
		transform: rotate(-90deg);
		transform-origin: 50% 50%;
	}
}
