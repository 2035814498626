/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.TableSimple .ReactTable {
  border: none; }
  .TableSimple .ReactTable .rt-thead {
    background: #0063ca;
    color: white;
    padding: 5px;
    font-weight: normal;
    border-radius: 10px;
    margin-bottom: 5px; }
  .TableSimple .ReactTable .rt-tbody .rt-tr-group {
    margin-bottom: 10px;
    border-radius: 10px;
    border: none;
    box-shadow: 1px 3px 8px rgba(100, 100, 100, 0.2); }
  .TableSimple .ReactTable .rt-tbody .rt-td {
    align-self: center;
    text-align: center;
    white-space: normal;
    width: inherit;
    flex: none; }

.ReactTable .rt-tbody .rt-td {
  border: none; }
