.FormLogin {
  font-family: "Open Sans";
  .field {
    margin-right: 10px;
    .control.login-submit {
      text-align: center;
    }
    .button.is-info.is-rounded {
      width: 150px;
    }
  }
  .button-submit {
    margin-top: 10px;
  }
}

.hero.is-info a:not(.button):not(.dropdown-item):not(.tag).blue-link {
  color: #000000;
}

.pass-specs {
  margin: 20px 0;

  li {
    margin-bottom: 5px;

    span {
      font-size: 13px;
      color: #272727;
      line-height: 1.75em;
    }
  }
}