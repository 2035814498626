.CreditRequestForm__main {
    form {
        display: flex;
        flex-direction: column;
        width: 50%;
        margin: 20px auto;

        .form-field {
            display: flex;
            flex-direction: column;
            margin-bottom: 15px;

            label {
                margin: 0 0 5px 10px;
            }
            .small-input {
                width: 35%;
            }
        }

        .form-BTNS {
            display: flex;
            align-items: center;

            .button {
                flex: 1;
                margin: 15px;
            }
        }
    }
}