/*Agrega colores por defecto de la app para ser exportados en los scss y seteados en la libreria de estilos por defecto
  ya sea bulma, boostrap, etc.*/
/*Azules para demo-asia*/
/*variables de bulma*/
/*Variables en arrays*/
/*Sizes*/
.Aside {
  margin-bottom: 1.5rem;
  min-height: 80vh;
  width: 20%; }
  .Aside a {
    color: #A6192E;
    text-decoration: none; }
  .Aside ul.sidebar-elements > li.border-bar div {
    padding: 1.3rem 0 1.3rem 1.2rem;
    font-size: 14px;
    color: #646464; }
  .Aside ul.sidebar-elements > li.border-bar a {
    display: flex;
    align-items: center; }
  .Aside .name-item {
    padding: .2rem 0; }
    .Aside .name-item span {
      color: #A6192E; }
  .Aside .be-left-sidebar {
    background-color: #0278f3;
    color: #f8fefc;
    display: flex;
    flex-direction: column;
    border-right: 1px solid #e3e3e3;
    height: 100%;
    padding: 30px 20px;
    left: 0px;
    top: 4em;
    z-index: 199; }
    .Aside .be-left-sidebar .left-sidebar-toggle {
      display: none;
      padding: 16px 24px;
      font-weight: 300;
      font-size: 27px;
      line-height: 22px;
      text-align: right;
      color: #c5c5c5;
      cursor: pointer; }
    .Aside .be-left-sidebar .left-sidebar-wrapper,
    .Aside .be-left-sidebar .left-sidebar-spacer,
    .Aside .be-left-sidebar .left-sidebar-scroll,
    .Aside .be-left-sidebar .left-sidebar-content {
      display: block;
      position: relative;
      overflow: visible; }
    .Aside .be-left-sidebar .left-sidebar-spacer {
      border-top: 2px solid #f5f5f5;
      height: 100%;
      display: table-row; }
      .Aside .be-left-sidebar .left-sidebar-spacer .open {
        display: block; }
    .Aside .be-left-sidebar .left-sidebar-scroll {
      position: relative;
      height: 100%;
      width: 100%; }
    .Aside .be-left-sidebar .left-sidebar-content {
      position: relative;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      padding-top: 0; }
    .Aside .be-left-sidebar .sidebar-elements {
      margin: 0;
      padding: 0; }
      .Aside .be-left-sidebar .sidebar-elements > li {
        list-style: none;
        width: 100%; }
      .Aside .be-left-sidebar .sidebar-elements > li > a .icon {
        line-height: 18px;
        font-size: 19px;
        min-width: 19px;
        margin-right: 9px;
        text-align: center;
        vertical-align: middle; }
      .Aside .be-left-sidebar .sidebar-elements > li.active > a {
        font-weight: 500;
        color: #A6192E;
        background-color: #0063ca;
        border-radius: 10px; }
      .Aside .be-left-sidebar .sidebar-elements > li > a {
        display: block;
        padding: 0 10px;
        margin-bottom: 7px;
        color: #646464;
        font-size: 12px;
        line-height: 38px;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .Aside .be-left-sidebar .sidebar-elements > li.active > a > span {
        color: #A6192E; }
      .Aside .be-left-sidebar .sidebar-elements > li > a {
        padding: 8px 20px;
        font-weight: 800;
        margin-top: 5px; }
      .Aside .be-left-sidebar .sidebar-elements > li.open > ul {
        display: block; }
      .Aside .be-left-sidebar .sidebar-elements > li ul {
        display: none;
        background-color: #f5f5f5;
        list-style: none; }
      .Aside .be-left-sidebar .sidebar-elements > li ul.open {
        display: flex;
        flex-direction: column; }
      .Aside .be-left-sidebar .sidebar-elements > li ul > li > a {
        padding: 6px 0 6px 29px;
        color: white;
        font-size: 13px;
        font-weight: lighter;
        display: block;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap; }
      .Aside .be-left-sidebar .sidebar-elements > li ul > li.active, .Aside .be-left-sidebar .sidebar-elements > li ul > li:hover {
        background-color: #1b85f3;
        border-radius: 5%;
        font-weight: bold; }
      .Aside .be-left-sidebar .sidebar-elements > li ul > li.active a, .Aside .be-left-sidebar .sidebar-elements > li ul > li:hover a {
        color: white; }
      .Aside .be-left-sidebar .sidebar-elements > li > a:hover, .Aside .be-left-sidebar .sidebar-elements > li > a:hover > span {
        color: #A6192E;
        transition: ease-in-out 500ms;
        -webkit-transition: ease-in-out 500ms; }
      .Aside .be-left-sidebar .sidebar-elements > li > a:hover {
        background-color: #0278f3;
        border-radius: 10px; }
    @media (max-width: 1023px) {
      .Aside .be-left-sidebar {
        background-color: #f5f5f5;
        border-bottom: 1px solid #e3e3e3;
        border-right-width: 0;
        left: 0;
        position: relative;
        z-index: 0; }
        .Aside .be-left-sidebar .left-sidebar-wrapper,
        .Aside .be-left-sidebar .left-sidebar-spacer,
        .Aside .be-left-sidebar .left-sidebar-scroll,
        .Aside .be-left-sidebar .left-sidebar-content {
          display: block;
          position: relative;
          overflow: visible; }
        .Aside .be-left-sidebar .left-sidebar-spacer {
          border-top: 2px solid #f5f5f5; }
        .Aside .be-left-sidebar .left-sidebar-spacer.open {
          display: block; }
        .Aside .be-left-sidebar .left-sidebar-toggle {
          display: block; }
        .Aside .be-left-sidebar .left-sidebar-content {
          padding-bottom: 0; }
        .Aside .be-left-sidebar .sidebar-elements {
          /* & > li:hover > ul {
          display: none;
        } */ }
          .Aside .be-left-sidebar .sidebar-elements > li {
            border-top: 2px solid #EEEEEE; }
          .Aside .be-left-sidebar .sidebar-elements > li > a {
            text-align: left;
            position: relative; }
          .Aside .be-left-sidebar .sidebar-elements > li > a:hover > span {
            color: inherit; }
          .Aside .be-left-sidebar .sidebar-elements > li > ul {
            position: relative;
            height: auto;
            width: 100%;
            left: 0; }
          .Aside .be-left-sidebar .sidebar-elements > li > ul > li.title {
            display: none; }
          .Aside .be-left-sidebar .sidebar-elements > li:hover {
            background-color: inherit; }
          .Aside .be-left-sidebar .sidebar-elements li.open > ul {
            display: block; }
        .Aside .be-left-sidebar .show-up-down {
          -moz-transition-duration: 0.75s;
          -webkit-transition-duration: 0.75s;
          -o-transition-duration: 0.75s;
          transition-duration: 0.75s;
          -moz-transition-timing-function: ease-in;
          -webkit-transition-timing-function: ease-in;
          -o-transition-timing-function: ease-in;
          transition-timing-function: ease-in;
          max-height: 1000px;
          overflow: hidden; }
        .Aside .be-left-sidebar .hide-down-up {
          overflow: hidden;
          max-height: 0;
          padding-top: 0;
          padding-bottom: 0;
          margin-top: 0;
          margin-bottom: 0;
          -moz-transition-duration: 0.75s;
          -webkit-transition-duration: 0.75s;
          -o-transition-duration: 0.75s;
          transition-duration: 0.75s;
          -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
          transition-timing-function: cubic-bezier(0, 1, 0.5, 1); }
        .Aside .be-left-sidebar:before {
          content: "";
          display: none;
          position: fixed;
          z-index: -1;
          width: inherit;
          top: 0;
          left: 0;
          bottom: 0;
          background-color: inherit;
          border-right: 1px solid #e3e3e3; } }
    @media (min-width: 1024px) {
      .Aside .be-left-sidebar {
        border-right: none; }
        .Aside .be-left-sidebar .left-sidebar-spacer {
          display: table-row; }
        .Aside .be-left-sidebar .sidebar-elements > li {
          float: left;
          position: static; }
        .Aside .be-left-sidebar .sidebar-elements > li > a {
          line-height: normal;
          text-align: center; }
        .Aside .be-left-sidebar .sidebar-elements > li > a span {
          display: block; }
        .Aside .be-left-sidebar .sidebar-elements > li > a .icon {
          margin-right: 0; }
        .Aside .be-left-sidebar .sidebar-elements > li > ul {
          height: auto;
          min-width: 25%;
          background-color: transparent; }
        .Aside .be-left-sidebar .sidebar-elements > li > ul > li {
          position: relative;
          margin-right: 5px;
          float: left;
          border-radius: 5px; } }
    @media (min-width: 1024px) {
      .Aside .be-left-sidebar .sidebar-elements > li > a > span {
        display: inline; }
      .Aside .be-left-sidebar .sidebar-elements > li > a {
        line-height: 40px; }
      .Aside .be-left-sidebar .sidebar-elements > li > a > i.icon {
        margin-right: 5px; } }
  .Aside .border-bar {
    border-bottom: 1px solid #e3e3e3; }
  .Aside .separador-verde {
    border-bottom: 2px solid #C8102E; }
  .Aside .open > a {
    outline: 0; }
  .Aside .mdi {
    display: inline-block;
    font: normal normal normal 14px/1 'Material Icons';
    font-size: inherit;
    text-rendering: auto;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale; }
  .Aside .mdi-account:before {
    content: '\f207'; }
  .Aside .mdi-edit:before {
    content: '\f158'; }
  .Aside ul ul,
  .Aside ol ul,
  .Aside ul ol,
  .Aside ol ol {
    margin-bottom: 0; }
  .Aside ul,
  .Aside ol {
    line-height: 20px; }
  .Aside ul,
  .Aside ol {
    margin-top: 0;
    margin-bottom: 9px; }
  @media (max-width: 1023px) {
    .Aside .be-offcanvas-menu .be-left-sidebar .left-sidebar-wrapper {
      display: table; } }

#left-sidebar-sticky {
  position: sticky;
  top: 100px; }
